jQuery(document).ready(function(jQuery) {

	var schermbreedte = jQuery( window ).width();
	jQuery( window ).resize(function() {
		schermbreedte = jQuery( window ).width();
	});



	/* --------- open/close zoekformulier --- */
	jQuery(".openzoek").on("click",function(e){
		e.preventDefault();
		jQuery('#zoekformulier').toggle(300);
	})


	/* --------- open/close hoofdmenu --- */
	jQuery("#open-hoofdmenu a").on("click",function(e){
		e.preventDefault();
		jQuery('#hoofdmenu').slideToggle(300);
	})



	/* =============== click op sluit knop van info popup  ===  */
	jQuery(".openslider a, .sluiten").on("click",function(e) {
		jQuery('#slidercontainer').fadeToggle(300, function() {
			jQuery('#slidercontainer .responsiveslider').resize(); /* resize omdat initieel slider niet zichtbaar is en dan worden de afm. verkeerd berekend */
		});
	});

	/* =========== close popupgroot op ESC === */
	window.addEventListener('keydown', function(e) {
		console.log( e.target.nodeName );
		if((e.key=='Escape'||e.key=='Esc'||e.keyCode==27)){
			e.preventDefault();
			if( jQuery('#slidercontainer').is(':visible')) jQuery('#slidercontainer').fadeToggle();
			return false;
		}
	}, true);

	/* --------- open/close mobile menu --- */
	jQuery("#hamburger").on("click",function(e){
		e.preventDefault();
		jQuery('#navigatie-mobiel').slideToggle(300);
		jQuery("header").toggleClass('zindex');
		jQuery("#hamburger").toggleClass("toggled");
	})


});
